import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/share2douyin',
    name: 'share2douyin',
    component: () => import('../views/Share2DouyinIM')
  },
  {
    path: '/ebook',
    name: 'ebook',
    component: () => import('../views/Ebook'),
    meta: {
      title: '无界电子书下载 - TXT小说下载,TXT电子书免费下载,全本完结TXT小说下载,全集小说下载,TXT小说下载,TXT电子书免费下载,热门全本小说网站,完结小说,人物传记,修仙小说,穿越小说,言情小说,都市小说,耽美小说,同人小说,重生小说,人物传记',
      content: {
        Keywords: 'TXT电子书免费下载 全本完结TXT小说下载 免费完结全本TXT小说下载 TXT小说下载,TXT电子书免费下载,全本完结TXT小说下载,全集小说下载,TXT小说下载,TXT电子书免费下载,热门全本小说网站,完结小说,人物传记,修仙小说,穿越小说,言情小说,都市小说,耽美小说,同人小说,重生小说,人物传记',
        description: '免费电子书小说下载 完结小说下载 手机小说下载 全集小说下载,TXT小说下载,TXT电子书免费下载,热门全本小说网站,完结小说,人物传记,历史经济 www.zerohi.icu'
      }
    }
  },
  {
    path: '/ip',
    name: 'ip',
    component: () => import('../views/IPTool'),
    meta: {
      title: 'IP地址定位 IP地理位置查询 - 无界小说网',
      content: {
        Keywords: 'IP地址归属地查询,IP地址追踪,在线IP地址查询,IP地址定位器,IP地址定位服务',
        description: '无界小说网为你提供全面的IP地址查询服务,IP地址归属地查询,IP地址追踪,在线IP地址查询,IP地址定位器,IP地址定位服务等等。'
      }
    }
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('../views/PhoneNumber'),
    meta: {
      title: '手机号码归属地查询 电话号码查询 - 无界小说网',
      content: {
        Keywords: '手机归属地查询,手机号码归属地查询,长途电话区号,电话号码查询,固定号码归属地查询',
        description: '无界小说网为你提供全面的手机号码归属地查询服务,ip地址查询工具,ip地址查询定位,ip地址查询位置，ip地址查询本机,ip地址查询精确位置等等。'
      }
    }
  },
]


// https://router.vuejs.org/zh/guide/essentials/history-mode.html
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
